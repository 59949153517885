<template lang="pug">
main(v-if='blogPost', data-view)
  .image-wrapper
    prismic-image(:field='blogPost.data.image', :style='{ "object-position": `50% ${blogPost.data.image_position}%` }')
  article
    .meta
      span.meta__date {{ blogPost.first_publication_date | formatDate }}
    span.tag {{ blogPost.data.tag }}
    prismic-rich-text.title(:field='blogPost.data.title')
    template(v-for='slice in blogPost.data.body')
      template(v-if='slice.slice_type === "content_"')
        prismic-rich-text(:field='slice.primary.content1')
      template(v-if='slice.slice_type === "image_text"')
        .image-text-section(:class='{ "image-right": slice.primary.image_position1 }')
          prismic-rich-text.image-text-section__text(:field='slice.primary.text')
          prismic-image(:field='slice.primary.image_field')
      common-button(
        v-if='slice.slice_type === "button_programs_page"',
        label='Voir les programmes',
        variant='orange',
        :path='{ name: "Courses" }'
      )
</template>

<script>
import { formatDate } from '@/filters/dateFormatter.js'

export default {
  name: 'BlogPost',
  components: {
    CommonButton: () => import('@/components/CommonButton')
  },
  filters: { formatDate },
  metaInfo() {
    return {
      title: this.blogPost && this.blogPost.data.title1,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.blogPost && this.blogPost.data.description
        }
      ]
    }
  },
  data() {
    return {
      blogPost: null
    }
  },
  created() {
    this.getContent()
  },
  methods: {
    async getContent() {
      const response = await this.$prismic.client.getByUID('blog_post', this.$route.params.uid)
      this.blogPost = response
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
@import '@/styles/colors.scss';

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
}

.image-wrapper {
  width: 100%;
  display: flex;
  max-height: 300px;
  margin-bottom: 3rem;

  img {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
  }
}

article {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  > * {
    max-width: 900px;
  }

  .meta {
    font-size: 0.8rem;
    color: rgba($darkBlue, 0.5);
    font-family: 'Libre Franklin', sans-serif;

    &__author {
      margin-right: 0.5rem;
    }
  }

  .tag {
    color: $orange;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 1.3rem;
  }

  .title {
    font-size: 1.5rem;
    line-height: 2rem;
    color: $darkBlue;
  }

  @include medium-screen {
    .image-wrapper {
      max-height: 300px;
    }
  }
  @include large-screen {
    .image-wrapper {
      max-height: 400px;
    }
  }

  .image-text-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1rem;
    column-gap: 2rem;

    &__text {
      display: flex;
      align-items: center;
    }

    img {
      border-radius: 20px;
      max-height: 400px;
      object-fit: cover;
      order: -1;
    }

    &.image-right {
      img {
        order: inherit;
      }
    }

    > * {
      flex-basis: 45%;
      flex-grow: 1;
      min-width: 300px;
    }
  }
}

::v-deep {
  ol {
    font-family: 'Libre Franklin', sans-serif;
    line-height: 1.6rem;
    margin-bottom: 1rem;
    // list-style: decimal;
  }
  li {
    margin-bottom: 1rem;
  }
}

::v-deep {
  a {
    word-break: break-all;
  }
}
</style>
